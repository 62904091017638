import Sortable from "stimulus-sortable";

export default class extends Sortable {
  connect() {
    super.connect();
    // console.log("Do what you want here.");

    // The sortable.js instance.
    this.sortable;

    // Your options
    this.options;

    // Your default options
    this.defaultOptions;
  }

  onUpdate(event) {
    super.onUpdate(event);

    const itemID = event.item.dataset.id;
    const newPosition = event.newIndex + 1;

    fetch(`/portfolio_projects/${itemID}/reorder`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
      },
      body: JSON.stringify({ position: newPosition }),
    });
    // .then((response) => response.text()) // Change this line
    // .then((text) => console.log(text)); // And this line
  }

  // You can set default options in this getter for all sortable elements.
  get defaultOptions() {
    return {
      animation: 500,
    };
  }
}
